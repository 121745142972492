import React from 'react';
import { graphql } from 'gatsby';
import { PageTemplate } from '../gatsby-theme-wordpress/templates/page';

export const pageQuery = graphql`
  query Vacancy($id: String) {
    page: wordpressWpVacancies(id: { eq: $id }) {
      yoast_meta {
        name
        content
        property
      }
      yoast_title
      yoast_json_ld {
        wordpress__context
        wordpress__graph {
          wordpress__type
          wordpress__id
          name
          description
          width
          height
          inLanguage
          datePublished
          dateModified
          caption
          isPartOf {
            wordpress__id
          }
          primaryImageOfPage {
            wordpress__id
          }
          url
        }
      }

      fancyheader: featured_media {
        ...Fancyheader
      }
      title
      content
      acf {
        form: contact_form
      }
    }
  }
`;

const VacanciesTemplate = (props) => (
  <PageTemplate {...props} withShare />
);

export default VacanciesTemplate;
